
export enum CaptureEventIds {
  NotInitialized = 0,// this id should never be received
	DeviceArrival = 1,
	DeviceRemoval = 2,
	Terminate = 3,
	Error = 4,
	DecodedData = 5,
	Power = 6,
	Buttons = 7,
	BatteryLevel = 8,// Battery Level has changed
	ListenerStarted = 9,// the communucation port listener has started
	DeviceOwnership = 10,// Notification when a device ownership changes
  DeviceManagerArrival = 11,// Device Manager Arrival (BLE)
	DeviceManagerRemoval = 12,// Device Manager Removal (BLE)
	DeviceDiscovered = 13, // Notification when a device has been discovered (BLE)
	DiscoveryEnd = 14, // Notification when a discovery has ended (BLE)
	CcidStatus = 15, // CCID Status notification (for PC/SC interface)
	LastID = 16// just for marking the end of this enum
};

export enum CaptureEventTypes {
  None = 0,
  Byte = 1,
  Ulong = 2,
  Array = 3,
  String = 4,
  DecodedData = 5,
  DeviceInfo = 6, // contains the device guid, type, and name
  LastID = 7// just for marking the end of this enum
};

export class CaptureEvent<T> {
  id: CaptureEventIds;
  type: CaptureEventTypes;
  value?: T;
  constructor(id: CaptureEventIds, type: CaptureEventTypes, value?: T) {
    this.id = id;
    this.type = type;
    if(value) {
      this.value = value;
    }
  }
}
