//PropertyValues.ts
//This file is generated when calling npm run update
//
//

// Data Confirmation Mode indicates what is 
// expected to the send the Data ACK back to the scanner
export enum DataConfirmationMode {

	// use the device configuration (Local Confirmation or App)
	ModeOff = 0,
	// the device confirms the decoded data locally
	ModeDevice = 1,
	// Capture confirms the decoded data as it receives them and there is one app
	ModeCapture = 2,
	// the Application confirms the decoded data as it receives them
	ModeApp = 3
};

// Device Data Acknowledgment mode
export enum DeviceDataAcknowledgment {

	// the device won't locally acknowledge decoded data
	Off = 0,
	// the device will locally acknowledge decoded data
	On = 1
};

// Security Mode
export enum SecurityMode {

	// No security
	None = 0,
	// communication protected by authentication
	Authentication = 1,
	// communication protected by authentication and encrytion
	AuthenticationEncryption = 2
};

// trigger parameter
export enum Trigger {

	// start a read
	Start = 1,
	// stop a read
	Stop = 2,
	// enable the trigger
	Enable = 3,
	// disable the trigger
	Disable = 4,
	// start a read in continuous
	ContinuousScan = 5
};

// Delete the pairing of the device
export enum DeletePairing {

	// delete the current pairing
	Current = 0,
	// delete all the pairing of the device
	All = 1
};

// sound configuration for the a type of action
export enum SoundActionType {

	// sound configuration for a good read action
	GoodRead = 0,
	// sound configuration for a good read local action
	GoodReadLocal = 1,
	// sound configuration for a bad read action
	BadRead = 2,
	// sound configuration for a bad read local action
	BadReadLocal = 3
};

// Sound frequency configuration
export enum SoundFrequency {

	// no frequency
	None = 0,
	// Low pitch frequency
	Low = 1,
	// Medium pitch frequency
	Medium = 2,
	// High pitch frequency
	High = 3
};

// Rumble configuration for the a type of action
export enum RumbleActionType {

	// Rumble configuration for a good read action
	GoodRead = 0,
	// Rumble configuration for a good read local action
	GoodReadLocal = 1,
	// Rumble configuration for a bad read action
	BadRead = 2,
	// Rumble configuration for a bad read local action
	BadReadLocal = 3
};

// Define the action for a local decode
export enum LocalDecodeAction {

	// no action
	None = 0,
	// Beep when a local decode occurs
	Beep = 1,
	// Flash the LED when a local decode occurs
	Flash = 2,
	// Rumble when a local decode occurs
	Rumble = 4
};

// Define the LED for data confirmation
export enum DataConfirmationLed {

	// no LED
	None = 0,
	// make the LED flash in green
	Green = 1,
	// make the LED flash in red
	Red = 2
};

// Define the sound for data confirmation
export enum DataConfirmationBeep {

	// no sound
	None = 0,
	// short beep for success
	Good = 1,
	// long beep for failure
	Bad = 2
};

// Define the rumble for data confirmation
export enum DataConfirmationRumble {

	// no sound
	None = 0,
	// short rumble for success
	Good = 1,
	// long rumble for failure
	Bad = 2
};

// Define the flash setting
export enum Flash {

	// turn off the flash
	Off = 0,
	// turn on the flash
	On = 1
};

// Define the Softscan experience (iOS-iPadOs only)
export enum SoftScan {

	// Enable SoftScan
	Enable = 0,
	// Disable SoftScan
	Disable = 1,
	// SoftScan is not supported, and cannot be enabled
	NotSupported = 2,
	// SoftScan is supported and can be enabled
	Supported = 2
};

// Define the possible power states
export enum PowerState {

	// The power state is unknown
	Unknown = 0,
	// The power state is on battery
	OnBattery = 1,
	// The power state is on cradle
	OnCradle = 2,
	// The power state in on AC
	OnAc = 4
};

// Define the monitor feature (DEBUG build only)
export enum MonitorDbg {

	// define the level for the debug traces
	Level = 1,
	// define the output channel for the debug traces
	Channel = 2,
	// define the number of lines in the file
	FileLineLevel = 3
};

// identifiers for the statistic counters
export enum Counter {

	// use for convenience only to skip a counter
	Skip = -1,
	// unknow counter
	Unknown = 0,
	// number of connections
	Connect = 1,
	// number of disconnections
	Disconnect = 2,
	// number of unbound operation
	Unbound = 3,
	// number of reset to factory default
	FactoryReset = 4,
	// number of reads (scans)
	Reads = 5,
	// number of trigger button released
	TriggerButtonUp = 6,
	// number of trigger button pressed
	TriggerButtonDown = 7,
	// number of power button released
	PowerButtonUp = 8,
	// number of power button pressed
	PowerButtonDown = 9,
	// number of minutes in AC power
	OnAcTimeInMinutes = 10,
	// number of minutes on battery
	OnBatTimeInMinutes = 11,
	// number of RFCOMM sent (SSI only)
	RfcommSend = 12,
	// number of RFCOMM received (SSI only)
	RfcommReceive = 13,
	// number of RFCOMM discarded (SSI only)
	RfcommReceiveDiscarded = 14,
	// number of UART sent (SSI only)
	UartSend = 15,
	// number of UART received (SSI only)
	UartReceive = 16,
	// number of UART discarded (SSI only)
	UartReceiveDiscarded = 17,
	// number of left button press (CRS only)
	ButtonLeftPress = 18,
	// number of left button release (CRS only)
	ButtonLeftRelease = 19,
	// number of right button press (CRS only)
	ButtonRightPress = 20,
	// number of right button release (CRS only)
	ButtonRightRelease = 21,
	// number of ring unit detach (CRS only)
	RingUnitDetach = 22,
	// number of ring unit attach (CRS only)
	RingUnitAttach = 23,
	// number of decoded bytes (7x only ISCI)
	DecodedBytes = 24,
	// number of abnormal shutdowns (7x only ISCI)
	AbnormalShutdowns = 25,
	// number of battery charge cycles (7x only ISCI)
	BatteryChargeCycles = 26,
	// number of battery charge count (7x only ISCI)
	BatteryChargeCount = 27,
	// number of power on (only 8Ci)
	PowerOn = 28,
	// number of power off (only 8Ci)
	PowerOff = 29,
	// number of stand mode change (only 7X/Q 7630 and higher)
	StandModeChange = 30
};

// Disconnect parameters to instruct the device what to do after disconnection
export enum Disconnect {

	// disconnect and then start the current profile
	StartProfile = 0,
	// Disconnect and disable radio (low power)
	DisableRadio = 1
};

// Select a profile for the device (None, SPP, HID)
export enum ProfileSelect {

	// The device is in acceptor mode, not trying to connect to any host
	None = 0,
	// The device is in App mode
	Spp = 1,
	// The device is in Basic mode, sometimes referred as Keyboard emulation
	Hid = 2
};

// Configure a profile for the device (None, Acceptor, Initiator)
export enum ProfileConfig {

	// The device is in either acceptor or initiator mode, meaning not even discoverable
	None = 0,
	// The device is discoverable and will accept any connection
	Acceptor = 1,
	// The device initiates a connection to the Bluetooth address specified in the Profile Configuration
	Initiator = 2
};

// Configuration masks for selecting the notifications the device should send to the host
export enum Notifications {

	// The device sends a notification when the trigger button is pressed
	TriggerButtonPress = 1,
	// The device sends a notification when the trigger button is released
	TriggerButtonRelease = 2,
	// The device sends a notification when the power button is pressed
	PowerButtonPress = 4,
	// The device sends a notification when the power button is released
	PowerButtonRelease = 8,
	// The device sends a notification when the power state changes (battery to AC or vice-versa) (not supported on all device)
	PowerState = 16,
	// The device sends a notification when the battery level changed (not supported on all device)
	BatteryLevelChange = 32
};

// Identifies the timers used in the device, (trigger lock, disconnected, connected)
export enum Timer {

	// The trigger button stays ineffective for the specified amount of time 
	// or until the device receives a data confirmation command.
	AutoLock = 1,
	// This timer specifies the amount of time the device stays on while it is not connected to any host.
	PowerOffDisconnected = 2,
	// This timer specifies the amount of time the device stays on while it is connected to a host.
	PowerOffConnected = 4
};

// Identifies the data format the device should send the data to the host
export enum DataFormat {

	// The device sends the data without any protocol overhead
	Raw = 0,
	// The device sends the data embedded in a protocol packet (default)
	Packet = 1,
	// The device sends only the RFID tag ID, (valid only for D600 NFC devices)
	IdOnly = 2,
	// The device sends the tag type and RFID tag ID, (valid only for D600 NFC devices)
	TagTypeAndId = 10,
	// The device sends only the tag data, (valid only for D600 NFC devices)
	DataOnly = 4,
	// The device sends the tag type and the tag data, (valid only for D600 NFC devices)
	TagTypeAndData = 12
};

// defines the operational mode of the device
export enum TriggerMode {

	// The device triggers a read only by pressing the trigger button
	LocalOnly = 1,
	// The device triggers a read by pressing the trigger button or by receiving the trigger command
	RemoteAndLocal = 2,
	// the device waits for the host to unlock the trigger
	AutoLock = 3,
	// the device locks and unlocks the trigger locally (default)
	NormalLock = 4,
	// The device triggers a read automatically without user intervention
	Presentation = 5
};

// defines the reason as of how the device connects to the host
export enum ConnectReason {

	// The device connects to the host from unknown reason
	Unknown = 0,
	// The device connects to the host just after power on
	PowerOn = 1,
	// The device connects to the host by reading the host address from a barcode
	Barcode = 2,
	// The device connects to the host by user action (usually a press on the trigger button)
	UserAction = 3,
	// The device connects to the host from a change of the host address in the profile configuration
	HostChange = 4,
	// The device connects to the host after a retry (device coming back to the radio range)
	Retry = 5
};

// The start up role SPP defines the start up role when using the SPP profile.
export enum StartUpRoleSpp {

	// The device returns to acceptor mode, not initiating a connection to a host.
	Acceptor = 0,
	// The device uses the last role configuration upon startup.
	LastRole = 1
};

// The connect beep configuration allows to turn off or on the connection beep when the scanner connects
export enum ConnectBeepConfig {

	// The device won't beep upon connection to a host.
	NoBeep = 0,
	// The device beeps when connecting to the host.
	Beep = 1
};

// The stand configuration defines the operational mode of the device when used with a stand.
export enum StandConfig {

	// Mobile mode Works like today existing firmware Engine is always in 
	//  trigger mode Engine hibernate enabled
	MobileMode = 0,
	// Stand mode Engine always in presentation mode Engine hibernate 
	// disabled Scanner turns on immediately Power timers disabled Connection 
	// retries forever
	StandMode = 1,
	// Detect mode On stand engine in presentation mode On stand engine 
	// hibernate disabled On stand charging led state not show On stand 
	// scanner turns on immediately On stand power timers disabled On stand 
	// connection retries forever Off stand engine in level mode Off stand 
	// battery led state reported Off stand engine hibernate enabled Off stand 
	// power off timers running Off stand connection retries halt after max 
	// count
	DetectMode = 2,
	// Auto mode On stand engine in presentation mode On stand engine hibernate 
	// disabled On stand charging led state not show On stand scanner turns on 
	// immediately On stand power timers disabled On stand connection retries 
	// forever Off stand does nothing, engine remains in presentation mode Off 
	// stand trigger press causes engine to enter level mode Engine in level 
	// mode battery led state reported Engine in level mode hibernate enabled 
	// Engine in level mode power off timers running Engine in level mode 
	// connection retries halt after max count
	AutoMode = 3
};



