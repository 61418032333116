
import { RpcTransport } from './rpcTransport';
import HttpTransport from './httpTransport';
import MaracaTransport from './maracaTransport';
import { Logger } from './logger';

// this is for Typescript so it won't complain
// about checking the existence of the member
// in the window global instance
declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        maracaSendJsonRpc: any
      }
    }
  }
}

export default class Transport {
    static getTransport(logger?: Logger): RpcTransport {
      let transport: RpcTransport;
      if((window.webkit) &&
        (window.webkit.messageHandlers) &&
        (window.webkit.messageHandlers.maracaSendJsonRpc)) {
          transport = new MaracaTransport(null, logger);
      }
      else {
        transport = new HttpTransport(logger);
      }
      return transport;
    }
}
