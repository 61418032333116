import { JRpcRequest, JRpcResponse, JRpcEvent } from './jsonRpc'
import { RpcTransport, BaseTransport } from './rpcTransport';
import SktErrors from './gen/errors';
import { Logger } from './logger';

// this is for Typescript so it won't complain
// about checking the existence of the member
// in the window global instance
declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        maracaSendJsonRpc: any
      }
    },
    maraca: {
      receiveJsonRpc: (json: string) => void;
      replyJsonRpc: (json: string) => void;
    }
  }
}

interface ResponsePerJsonRpc {
  rpcId: number,
  responseCallback: (json: JRpcResponse<any>) => void
};

class InternalLogger implements Logger {
  log(message: string, arg: object): void {

  }
}

export default class MaracaTransport extends BaseTransport implements RpcTransport {
  callbacks: { (response: JRpcResponse<any>) : void }[];
  window: Window;
  handles: {handle: number}[];
  responses: ResponsePerJsonRpc[] = [];
  logger: Logger;
  constructor(depwindow?:Window, logger?: Logger) {
    super();
    this.logger = logger || new InternalLogger();
    if (depwindow) {
      this.window = depwindow;
    }
    else {
      this.window = window;
    }
    this.window.maraca = this.window.maraca || {
      receiveJsonRpc: (json: string) => void {},
      replyJsonRpc: (json: string) => void {},
    }; 
  }

  open(host: string, notification:(event: JRpcEvent<any>)=>void): Promise<{ handle: number }> {
// WE SHOULD USE SYMBOL FOR THE KEY (HANDLE) TO IDENTIFY A TRANSPORT CLIENT
// AND FOR THE RESPONSE CALLBACKS

//     sendJsonRpc(jsonRpc, responseCallback)
//      callbacks[jsonRpc.id] = responseCallback;
//      window.webkit.messageHandlers.maracaSendJsonRpc.postMessage(jsonRpc)
//
//      window.maraca.replyJsonRpc(jsonRpcResponse)
//      responseCallback = callbacks[jsonRpcResponse.id]
// responseCallback(jsonRpcResponse)
//
//      window.maraca.receiveJsonRpc(jsonRpcEvent)
// OnCaptureEvent(jsonRpcEvent)
    const newHandle = this.generateHandle();
    if (newHandle === 0) {
      return Promise.reject(SktErrors.ESKT_INVALIDHANDLE);
    }
    this.window.maraca.receiveJsonRpc = (json: string) => {

      try {
        const jsonRpcDecoded = decodeURI(json);
        const jsonRpc = JSON.parse(jsonRpcDecoded);
        notification(jsonRpc);
      }
      catch(ex) {
        // should we log a warning here???
      }
    };
    this.window.maraca.replyJsonRpc = (json:string) => {
      const decodedJsonRpc = decodeURI(json);
      this.dispatchResponse(decodedJsonRpc);
    }
    return Promise.resolve({handle: newHandle});
  }

  close(handle: number): Promise<number> {
    const index = this.handles.findIndex(h => h.handle === handle);
    if(index === -1) {
      return Promise.reject(SktErrors.ESKT_INVALIDHANDLE);
    }
    this.handles.splice(index,1);
    return Promise.resolve(SktErrors.ESKT_NOERROR);
  }

  send<T>(handle: number, request: JRpcRequest<T>): Promise<JRpcResponse<any>> {
    const jsonRpc = JSON.stringify(request);
    const promise = new Promise<JRpcResponse<any>>((resolve, reject)=>{
      const response: ResponsePerJsonRpc = {
        rpcId: request.id,
        responseCallback: (jsonRpcResponse: JRpcResponse<any>) => {
          resolve(jsonRpcResponse);
        }
      }
      const index = this.handles.findIndex(h => h.handle === handle);
      if(index === -1) {
        return reject(SktErrors.ESKT_INVALIDHANDLE);
      }
      this.responses.push(response);
      this.window.webkit.messageHandlers.maracaSendJsonRpc.postMessage(jsonRpc);
    });
    return promise;
  }

  dispatchResponse(jsonrpc: string) {
    try {
      const jsonRpc = JSON.parse(jsonrpc);
      // look for the corresponding callback
      const index = this.responses.findIndex(rpr => rpr.rpcId === jsonRpc.id);
      if (index !== -1) {
        const callback = this.responses[index];
        this.responses.splice(index, 1);
        callback.responseCallback(jsonRpc);
      }
      else {
        // should we log a warning here???
      }
    }
    catch(ex) {
      // should we log the exception here???
    }
  }
}
